<template>
  <div class="page-content">
    <!-- loading -->
    <div class="loader" v-if="isLoading">
      <ring-loader :loading="loading" :color="color1" :size="size"></ring-loader>
      <p>Please Wait...</p>
    </div>
    <div class="container-fluid">
      <div class="row flex-nowrap">
        <!-- sidebar -->
        <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark sidebar-container">
          <sidebar-menu></sidebar-menu>
        </div>
        <!-- view content -->
        <div class="col py-3 view-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebarMenu from "@/components/SidebarMenu.vue";
import RingLoader from 'vue-spinner/src/RingLoader.vue'
export default {
  components: {
    sidebarMenu,
    RingLoader,
  },
  data() {
    return {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '45px',
      margin: '2px',
      radius: '2px',
      isLoading: true
    }
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
    setTimeout(() => {
      document.querySelector('body').style.overflow = "visible";
    }, 1500);
  }
};
</script>

<style lang="scss">
body {
  overflow: hidden;
}

.loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 999;
  text-align: center;

  p {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }
}
</style>