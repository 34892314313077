import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false
// import axios
// import axios
import axios from "axios";

const base = axios.create({
  baseURL: "/api",
});

Vue.prototype.$axios = base;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
