<template>
  <div id="app">
    <master></master>
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Noto Sans", sans-serif;
}
:root {
  --dark-color: #212529;
  --blue-color: #0d6efd;
}
</style>

<script>
import master from "@/components/MasterPage";

export default {
  components: {
    master,
  },
};
</script>

