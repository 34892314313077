<template>
  <div class="page question-page">
    <div class="main-title">
      <div class="icon">
        <i class="fa fa-chevron-circle-right"></i>
      </div>
      <h3>Translation</h3>
    </div>

    <!-- form -->
    <div class="form">
      <div class="row">
        <div class="col-lg-8">
          <!-- from to -->
          <div class="form-group">
            <div class="row align-items-end">
              <!-- from select -->
              <div class="col-md-3">
                <label for="" class="mb-1">From</label>
                <select class="form-select" v-model="from">
                  <option value="">-- From --</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Cantonese">Cantonese</option>
                  <option value="Mandarin">Mandarin</option>
                  <option value="Thai">Thai</option>
                  <option value="Malay">Malay</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Japanese">Japanese</option>
                  <option value="German"> German</option>
                  <option value="English">English</option>
                  <option value="French">French</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
              <!-- change languages -->
              <div class="col-md-2">
                <div class="form-group text-center">
                  <button class="btn btn-primary px-3" @click="ExchangeLanguages">
                    <i class="fa fa-exchange"></i>
                  </button>
                </div>
              </div>
              <!-- to select -->
              <div class="col-md-3">
                <label for="" class="mb-1">To</label>
                <select class="form-select" v-model="to">
                  <option value="">-- To --</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Cantonese">Cantonese</option>
                  <option value="Mandarin">Mandarin</option>
                  <option value="Thai">Thai</option>
                  <option value="Malay">Malay</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Japanese">Japanese</option>
                  <option value="German"> German</option>
                  <option value="English">English</option>
                  <option value="French">French</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <label class="form-label">text</label>
            <textarea type="text" class="form-control" placeholder="Enter the text you want to translate" rows="4"
              v-model="topic.text"></textarea>
          </div>
          <div class="form-group mt-2">
            <button class="btn btn-primary" @click="getResult" :class="{
              disabled: !topic.text || topic.isDisabled || from == '' || to == '',
            }" :disabled="topic.text == null || topic.isDisabled || from == '' || to == ''">
              <i class=" fa fa-send"></i> Submit
              <i class="fa fa-refresh fa-spin ms-1" v-if="topic.inProgress"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <div class="row">
        <div class="col-lg-8">
          <div class="gpt-result">
            <textarea cols="30" rows="12" class="form-control" placeholder="The result will appear here ^_^"
              v-model="topic.sugesstion" disabled></textarea>
          </div>
          <div class="copy mt-2">
            <button class="btn btn-success" @click="copyTextToClipboard" v-if="topic.isDone">
              <i class="fa fa-copy"></i>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      from: "",
      to: "",
      topic: {
        text: "",
        sugesstion: "",
        inProgress: false,
        isDone: false,
        isDisabled: false,
        error: {
          errorMessage: "",
          hasError: false,
        },
      },
    };
  },
  methods: {
    getResult() {
      let from = this.from;
      let to = this.to;
      if (this.topic.text != "" && from != "" && to != "") {
        // reset data values
        this.resetFields(this.topic, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });
        const params = {
          model: process.env.VUE_APP_GPT_MODEL,
          prompt: `translate this text:"${this.topic.text}",from:"${from}", to:"${to}"`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let aiAnswer = result.data.choices[0].text;
              aiAnswer = aiAnswer.replace(/^\n+/, "").replaceAll("\n", "\n");
              this.topic.sugesstion = aiAnswer;
              this.topic.inProgress = false;
              this.topic.isDone = true;
              this.topic.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.topic, false);
            this.topic["error"].hasError = true;
            this.topic["error"].errorMessage =
              err?.message + " please try again or refresh page.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object["error"].hasError = !status;
      object["isDisabled"] = status;
      object["inProgress"] = status;
      object["isDone"] = !status;
      object["sugesstion"] = "";
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.topic.sugesstion).then(
        function () {
          alert("Copying to clipboard was successful!");
        },
        function (err) {
          alert("Could not copy text: ", err);
        }
      );
    },
    ExchangeLanguages(){
      [this.to,this.from] = [this.from,this.to];
    }
  },
};
</script>
