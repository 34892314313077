<template>
  <div class="page question-page">
    <div class="main-title">
      <div class="icon">
        <i class="fa fa-chevron-circle-right"></i>
      </div>
      <h3>Generate reply email</h3>
    </div>

    <!-- form -->
    <div class="form">
      <div class="row">
        <div class="col-lg-8">
          <!-- topic -->
          <div class="form-group">
            <label class="form-label">email msg</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Put the message you want to reply to here"
              rows="8"
              v-model="topic.title"
            ></textarea>
          </div>
          <div class="form-group mt-2">
            <button
              class="btn btn-primary"
              @click="getResult"
              :class="{
                disabled: !topic.title || topic.isDisabled,
              }"
              :disabled="
                topic.title == null || topic.isDisabled
              "
            >
              <i class="fa fa-send"></i> Submit
              <i class="fa fa-refresh fa-spin ms-1" v-if="topic.inProgress"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <div class="row">
        <div class="col-lg-8">
          <div class="gpt-result">
            <textarea
              cols="30"
              rows="12"
              class="form-control"
              placeholder="The result will appear here ^_^"
              v-model="topic.sugesstion"
              disabled
            ></textarea>
          </div>
          <div class="copy mt-2">
            <button
              class="btn btn-success"
              @click="copyTextToClipboard"
              v-if="topic.isDone"
            >
              <i class="fa fa-copy"></i>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      topic: {
        title: "",
        sugesstion: "",
        inProgress: false,
        isDone: false,
        isDisabled: false,
        error: {
          errorMessage: "",
          hasError: false,
        },
      },
    };
  },
  methods: {
    getResult() {
      if (this.topic.title != "") {
        // reset data values
        this.resetFields(this.topic, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });
        const params = {
          model: process.env.VUE_APP_GPT_MODEL,
          prompt: `Write a long and professional email as a reply to the following message:"${this.topic.title}"`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let aiAnswer = result.data.choices[0].text;
              aiAnswer = aiAnswer.replace(/^\n+/, "").replaceAll("\n", "\n");
              this.topic.sugesstion = aiAnswer;
              this.topic.inProgress = false;
              this.topic.isDone = true;
              this.topic.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.topic, false);
            this.topic["error"].hasError = true;
            this.topic["error"].errorMessage =
              err?.message + " please try again or refresh page.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object["error"].hasError = !status;
      object["isDisabled"] = status;
      object["inProgress"] = status;
      object["isDone"] = !status;
      object["sugesstion"] = "";
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.topic.sugesstion).then(
        function () {
          alert("Copying to clipboard was successful!");
        },
        function (err) {
          alert("Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
