<template>
  <div class="page question-page">
    <div class="main-title">
      <div class="icon">
        <i class="fa fa-chevron-circle-right"></i>
      </div>
      <h3>Question answer</h3>
    </div>

    <!-- form -->
    <div class="form">
      <div class="row">
        <div class="col-lg-8">
          <div class="form-group">
            <label class="form-label">Question</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Write your question here"
              rows="3"
              v-model="questionAnswer.question"
            ></textarea>
          </div>
          <div class="form-group mt-2">
            <button
              class="btn btn-primary"
              @click="getQuestion"
              :class="{
                disabled: !questionAnswer.question || questionAnswer.isDisabled,
              }"
              :disabled="
                questionAnswer.question == null || questionAnswer.isDisabled
              "
            >
              <i class="fa fa-send"></i> Submit
              <i
                class="fa fa-refresh fa-spin ms-1"
                v-if="questionAnswer.inProgress"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <div class="row">
        <div class="col-lg-8">
          <div class="gpt-result">
            <textarea
              cols="30"
              rows="8"
              class="form-control"
              placeholder="The result will appear here ^_^"
              v-model="questionAnswer.answer"
              disabled
            ></textarea>
          </div>
          <div class="copy mt-2">
            <button
              class="btn btn-success"
              @click="copyTextToClipboard"
              v-if="questionAnswer.isDone"
            >
              <i class="fa fa-copy"></i>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      questionAnswer: {
        question: "",
        answer: "",
        inProgress: false,
        isDone: false,
        isDisabled: false,
        error: {
          errorMessage: "",
          hasError: false,
        },
      },
    };
  },
  methods: {
    getQuestion() {
      if (this.questionAnswer.question != "") {
        // reset data values
        this.resetFields(this.questionAnswer, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });
        const params = {
          model: process.env.VUE_APP_GPT_MODEL,
          prompt: `Answer this question:${this.questionAnswer.question}`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let aiAnswer = result.data.choices[0].text;
              aiAnswer = aiAnswer.replace(/^\n+/, "").replaceAll("\n", "\n\n");
              this.questionAnswer.answer = aiAnswer;
              this.questionAnswer.inProgress = false;
              this.questionAnswer.isDone = true;
              this.questionAnswer.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.questionAnswer, false);
            this.questionAnswer["error"].hasError = true;
            this.questionAnswer["error"].errorMessage =
              err?.message + " please try again or refresh page.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object["error"].hasError = !status;
      object["isDisabled"] = status;
      object["inProgress"] = status;
      object["isDone"] = !status;
      object["answer"] = "";
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.questionAnswer.answer).then(
        function () {
          alert("Copying to clipboard was successful!");
        },
        function (err) {
          alert("Could not copy text: ", err);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.view-content {
  background: #f5f6fa;
}
.page {
  padding: 60px 70px 30px;
  text-transform: capitalize;
  .main-title {
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    .icon {
      font-size: 33px;
      margin-right: 10px;
      margin-top: -5px;
      color: var(--blue-color);
    }
    h3 {
      position: relative;
      display: inline-block;
      margin-bottom: 30px;
      color: var(--dark-color);
      font-size: 28px;
      font-weight: 600;
      &:after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        height: 2px;
        background: var(--dark-color);
        width: 100%;
      }
    }
  }
  .form-group {
    .form-label {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  .result {
    margin-top: 40px;
  }
}

.sidebar-container{
  width:300px
}
</style>