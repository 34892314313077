import Vue from "vue";
import VueRouter from "vue-router";
import AnswerQuestion from "../views/AnswerQuestion";
import ProfessionalVocabulary from "../views/ProfessionalVocabulary";
import ProductNames from "../views/ProductNames";
import RecipeGenerator from "../views/RecipeGenerator";
import RestaurantReview from "../views/RestaurantReview";
import StudyNotes from "../views/StudyNotes";
import Blog from "../views/Blog";
import SocialMediaPost from "../views/SocialMediaPost";
import ReplyEmail from "../views/ReplyEmail";
import Translation from "../views/Translation";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AnswerQuestion",
    component: AnswerQuestion,
  },
  {
    path: "/professional-vocabulary",
    name: "ProfessionalVocabulary",
    component: ProfessionalVocabulary,
  },
  {
    path: "/product-names",
    name: "ProductNames",
    component: ProductNames,
  },
  {
    path: "/recipe",
    name: "RecipeGenerator",
    component: RecipeGenerator,
  },
  {
    path: "/restaurant-review",
    name: "RestaurantReview",
    component: RestaurantReview,
  },
  {
    path: "/study-notes",
    name: "StudyNotes",
    component: StudyNotes,
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/social-media-post",
    name: "SocialMediaPost",
    component: SocialMediaPost,
  },
  {
    path: "/reply-email",
    name: "ReplyEmail",
    component: ReplyEmail,
  },
  {
    path: "/translation",
    name: "Translation",
    component: Translation,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
