<template>
  <div class="page question-page">
    <div class="main-title">
      <div class="icon">
        <i class="fa fa-chevron-circle-right"></i>
      </div>
      <h3>Blog topics generator </h3>
    </div>

    <!-- form -->
    <div class="form">
      <div class="row">
        <div class="col-lg-8">
          <!-- topic -->
          <div class="form-group">
            <label class="form-label">topic</label>
            <textarea type="text" class="form-control"
              placeholder="Type the topic here to produce an appropriate title,description,outline,content..." rows="4"
              v-model="topic.title"></textarea>
          </div>

          <!-- topics list -->
          <div class="form-group mt-4">
            <label for="" class="form-label">What Content You Are Interested Of Blogs You Like To Get a Suggestions For
              ?</label>
            <select v-model="selectTopic" class="form-select">
              <option value="">--please select a topic ---</option>
              <option value="Title">Title</option>
              <option value="Description">Description</option>
              <option value="Conclusion">Conclusion</option>
              <option value="Outline">Outline</option>
              <option value="Tags">Tags</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <button class="btn btn-primary" @click="getResult" :class="{
              disabled: !topic.title || topic.isDisabled || selectTopic == '',
            }" :disabled="topic.title == null || topic.isDisabled || selectTopic == ''">
              <i class="fa fa-send"></i> Submit
              <i class="fa fa-refresh fa-spin ms-1" v-if="topic.inProgress"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <div class="row">
        <div class="col-lg-8">
          <div class="gpt-result">
            <textarea cols="30" rows="12" class="form-control" placeholder="The result will appear here ^_^"
              v-model="topic.sugesstion" disabled></textarea>
          </div>
          <div class="copy mt-2">
            <button class="btn btn-success" @click="copyTextToClipboard" v-if="topic.isDone">
              <i class="fa fa-copy"></i>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      selectTopic: "",
      topic: {
        title: "",
        sugesstion: "",
        inProgress: false,
        isDone: false,
        isDisabled: false,
        error: {
          errorMessage: "",
          hasError: false,
        },
      },
    };
  },
  methods: {
    getResult() {
      if (this.topic.title != "" && this.selectTopic) {
        // reset data values
        this.resetFields(this.topic, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });
        const params = {
          model: process.env.VUE_APP_GPT_MODEL,
          prompt: `create blog ${this.selectTopic} about:"${this.topic.title}"`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let aiAnswer = result.data.choices[0].text;
              aiAnswer = aiAnswer.replace(/^\n+/, "").replaceAll("\n", "\n");
              this.topic.sugesstion = aiAnswer;
              this.topic.inProgress = false;
              this.topic.isDone = true;
              this.topic.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.topic, false);
            this.topic["error"].hasError = true;
            this.topic["error"].errorMessage =
              err?.message + " please try again or refresh page.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object["error"].hasError = !status;
      object["isDisabled"] = status;
      object["inProgress"] = status;
      object["isDone"] = !status;
      object["sugesstion"] = "";
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.topic.sugesstion).then(
        function () {
          alert("Copying to clipboard was successful!");
        },
        function (err) {
          alert("Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
