<template>
  <div class="page question-page">
    <div class="main-title">
      <div class="icon">
        <i class="fa fa-chevron-circle-right"></i>
      </div>
      <h3>Product Names Generator</h3>
    </div>

    <!-- form -->
    <div class="form">
      <div class="row">
        <div class="col-lg-8">
          <div class="form-group">
            <label class="form-label">Description</label>
            <textarea
              type="text"
              class="form-control"
              placeholder="Enter a description of the product for which you want to create a names"
              rows="3"
              v-model="product.description"
            ></textarea>
          </div>
          <div class="form-group mt-2">
            <button
              class="btn btn-primary"
              @click="getResult"
              :class="{
                disabled: !product.description || product.isDisabled,
              }"
              :disabled="product.description == null || product.isDisabled"
            >
              <i class="fa fa-send"></i> Submit
              <i
                class="fa fa-refresh fa-spin ms-1"
                v-if="product.inProgress"
              ></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- result -->
    <div class="result">
      <div class="row">
        <div class="col-lg-8">
          <div class="gpt-result">
            <textarea
              cols="30"
              rows="12"
              class="form-control"
              placeholder="The result will appear here ^_^"
              v-model="product.names"
              disabled
            ></textarea>
          </div>
          <div class="copy mt-2">
            <button
              class="btn btn-success"
              @click="copyTextToClipboard"
              v-if="product.isDone"
            >
              <i class="fa fa-copy"></i>
              copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_GPT_URL_SERVICE,
      API_KEY: process.env.VUE_APP_GPT_API_Key,
      product: {
        description: "",
        names: "",
        inProgress: false,
        isDone: false,
        isDisabled: false,
        error: {
          errorMessage: "",
          hasError: false,
        },
      },
    };
  },
  methods: {
    getResult() {
      if (this.product.description != "") {
        // reset data values
        this.resetFields(this.product, true);

        // create new openAi request
        const client = this.$axios.create({
          contentType: "application/json",
          headers: {
            Authorization: "Bearer " + this.API_KEY,
          },
        });
        const params = {
          model: process.env.VUE_APP_GPT_MODEL,
          prompt: `generate an appropriate name group suggestions for the product from the following description: "${this.product.description}"`,
          temperature: 1,
          max_tokens: 800,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        };

        client
          .post(`${this.APP_URL}`, params)
          .then((result) => {
            if (result.status === 200) {
              let aiAnswer = result.data.choices[0].text;
              aiAnswer = aiAnswer.replace(/^\n+/, "").replaceAll("\n", "\n\n");
              this.product.names = aiAnswer;
              this.product.inProgress = false;
              this.product.isDone = true;
              this.product.isDisabled = false;
            }
          })
          .catch((err) => {
            // reset fields values
            this.resetFields(this.product, false);
            this.product["error"].hasError = true;
            this.product["error"].errorMessage =
              err?.message + " please try again or refresh page.";
          });
      }
    },
    resetFields(object, status) {
      // reset data values
      object["error"].hasError = !status;
      object["isDisabled"] = status;
      object["inProgress"] = status;
      object["isDone"] = !status;
      object["names"] = "";
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.product.names).then(
        function () {
          alert("Copying to clipboard was successful!");
        },
        function (err) {
          alert("Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
